import { ExternalTracker, Platform, SraPlacement } from '@/@types/graphql-types';
import { TrackingHelper } from './tracking/tracking-helper';
import { captureMessageForSentry } from './sentry-helper';
import { ImpressionTrackingEvents } from '@/enums/events';
import { TrackingProviderPropertiesInterface } from './tracking/providers';
import { UC_TCF_LOCALSTORAGE_KEY } from '@/constants/user-centrics';
import { GDPR_WEB_LOCALES } from '@/constants/web-locales.constant';
import { WebLocale } from '@/enums/web-locale';
import { getSrFromBidId } from '@/components/sponsored-recommendations/useSponsoredRec';

const MACRO_TIMESTAMP = /\[(timestamp)]/i;
const MACRO_GDPR_CONSENT = '${GDPR}' as const;
const MACRO_GDPR_CONSENT_755 = /\${(GDPR_CONSENT_)([^]*?)}/; // We want to match any macros with ${GDPR_CONSENT_XXXXX}

// Exactag Macros
const MACRO_EXACTAG_TIMESTAMP = /rnd=TIMESTAMP/;
const MACRO_EXACTAG_SUPPLIER = /extPu=/;
const MACRO_EXACTAG_PLATFORM = /extSi=/;
const MACRO_EXACTAG_PLACEMENT = /extPm=/;
const MACRO_EXACTAG_CAMPAIGN_NAME = /extLi=/;

type CommonTrackerType = {
	webLocale?: WebLocale;
};

type FormatMacroImpressionType = CommonTrackerType & {
	tracker: string;
	placement?: SraPlacement;
	campaignName?: string;
};

type HandleExternalTrackerType = CommonTrackerType & {
	externalTrackers: ExternalTracker[];
	trackerType: ExternalTrackerFiredType;
	bidId: string;
	skipFetchExternalTrackers?: boolean;
};

type TrackerType = {
	externalTrackers?: ExternalTracker[];
	bidId: string;
	webLocale: WebLocale;
};

export type ExternalTrackerFiredType = 'external_impression_tracker_fired' | 'external_clickout_tracker_fired';

/**
 * Formatting an impression tracker, that may include impressions or clickout URL
 * This is mainly used for SRs
 *
 * At the moment, the trackers are only fired if consent is given,
 * so we can safely assume that this replacement is set to '1'.
 *
 * @param impression {number} a URL with the included [timestamp] macro
 * @param webLocale {WebLocale} The web locale of the page the user is viewing the app from
 * @param placement {SraPlacement} The placement of the SR campaign
 * @param campaignName {string} The name of the SR campaign
 *
 * @returns a formatted tracker
 */
export const formatExternalTracker = ({
	tracker,
	webLocale,
	placement,
	campaignName,
}: FormatMacroImpressionType): string => {
	if (!tracker.length) return '';

	const isLocaleGDPR = webLocale && GDPR_WEB_LOCALES.includes(webLocale);

	tracker = tracker.replace(MACRO_TIMESTAMP, Date.now().toString());
	tracker = tracker.replace(MACRO_GDPR_CONSENT, isLocaleGDPR ? '1' : '0');

	// Exactag Macros
	tracker = tracker.replace(MACRO_EXACTAG_TIMESTAMP, `rnd=${Date.now().toString()}`);
	tracker = tracker.replace(MACRO_EXACTAG_PLATFORM, `extSi=${Platform.Web.toLowerCase()}`);
	tracker = tracker.replace(MACRO_EXACTAG_SUPPLIER, `extPu=justwatch`);

	// Only fill in macros if received placement and campaignName
	if (placement) tracker = tracker.replace(MACRO_EXACTAG_PLACEMENT, `extPm=${placement}`);
	if (campaignName) tracker = tracker.replace(MACRO_EXACTAG_CAMPAIGN_NAME, `extLi=${campaignName}`);

	if (isLocaleGDPR) {
		tracker = tracker.replace(MACRO_GDPR_CONSENT_755, getTCString());
	}

	return tracker;
};

/**
 * A TC String’s primary purpose is to encapsulate and encode all the information disclosed
 * to a user and the expression of their preferences for their personal data processing
 * under the GDPR.
 *
 * The TC String is generated by the Consent Management Platform (Eg: User Centric), and is stored
 * in localStorage. So we need to access this and pass it back to the macro/s.
 *
 * @returns {string | null} The Transparency and Consent string based on the user consent
 */
export function getTCString() {
	try {
		// localStorage does not exist in server context
		if (!process.client) return null;

		// Retrieve the stored consent data
		const uc_tcf = localStorage.getItem(UC_TCF_LOCALSTORAGE_KEY);

		// No Usercentrics consent data found in local storage
		if (!uc_tcf) return null;

		// Parse the JSON string to an object
		const consentObject = JSON.parse(uc_tcf);
		return consentObject.tcString;
	} catch {
		return null;
	}
}

/**
 * Loop through all our external tracker urls, allowing us to
 * have multiple trackers
 *
 * @param externalTrackers {ExternalTracker[]} An array of external trackers
 * @param trackerType {ExternalTrackerFiredType} What type of tracker we are firing
 * @param bidId {string} The ID of the bid, used for tracking
 * @param skipFetchExternalTrackers {boolean} Used in experiments where we don't want to fire the tracker
 * @param webLocale {WebLocale} The web locale of the page the user is viewing the app from
 */
export const handleExternalClickoutTrackers = ({
	externalTrackers,
	trackerType,
	bidId,
	skipFetchExternalTrackers,
	webLocale,
}: HandleExternalTrackerType) => {
	const { placement, srFragment } = getSrFromBidId(bidId);
	externalTrackers.forEach(async ({ data }) => {
		if (data) {
			// TODO: if env variable is not production, handle the URLs differently
			const url = formatExternalTracker({
				tracker: data,
				webLocale,
				placement,
				campaignName: srFragment?.campaign?.name,
			});

			/**
			 * At the moment we cannot get the status due to no cors
			 * So we will manually say confirmed for now
			 */
			let status = 200;

			if (!skipFetchExternalTrackers) {
				try {
					await fetch(url, {
						mode: 'no-cors',
						cache: 'no-cache',
					});
				} catch (err: any) {
					captureMessageForSentry(
						'[Unable to send the external tracker]:',
						{
							error: err,
							where: '[impression-tracker-helper.js]: handleExternalClickoutTrackers()',
							requestInfo: {
								tracker: data,
								trackerType: trackerType,
								webLocale,
								placement,
								campaignName: srFragment?.campaign?.name,
							},
						},
						'error'
					);

					status = 418;
				}
			}

			const properties: TrackingProviderPropertiesInterface = {
				action: trackerType,
				label: bidId,
				property: data,
				value: status,
				nonInteraction: trackerType === 'external_impression_tracker_fired',
			};

			TrackingHelper.trackEvent(ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS, properties);
		}
	});
};

/**
 * Inject external HTML trackers into the body of the page
 *
 * @param externalTrackers {ExternalTracker[]} An array of external trackers
 * @param webLocale {WebLocale} The web locale of the page the user is viewing the app from
 * @param bidId {string} The ID of the bid, used for tracking
 */
export const injectExternalHtmlTrackers = ({
	externalTrackers,
	webLocale,
	bidId,
}: {
	externalTrackers: ExternalTracker[];
	webLocale: WebLocale;
	bidId: string;
}) => {
	const { placement, srFragment } = getSrFromBidId(bidId);

	externalTrackers.forEach(({ data }) => {
		if (data) {
			const formatImpression = formatExternalTracker({
				tracker: data,
				webLocale,
				placement,
				campaignName: srFragment?.campaign?.name,
			});
			const fragment = document.createRange().createContextualFragment(formatImpression);

			document.head.appendChild(fragment);
		}
	});
};

/**
 * Check to see if we have consent before possibility handling external clickout trackers
 *
 * @param externalTrackers {ExternalTracker[]} An array of external trackers
 * @param webLocale {WebLocale} The web locale of the page the user is viewing the app from
 * @param bidId {string} The ID of the bid, used for tracking
 */
export async function externalTrackingClickoutUrl({ externalTrackers, bidId, webLocale }: TrackerType) {
	if (!externalTrackers?.length) return;

	handleExternalClickoutTrackers({
		externalTrackers: externalTrackers ?? [],
		trackerType: 'external_clickout_tracker_fired',
		webLocale,
		bidId,
	});
}

/**
 * Check to see if we have consent before possibility handling external impression trackers
 *
 * @param externalTrackers {ExternalTracker[]} An array of external trackers
 * @param webLocale {WebLocale} The web locale of the page the user is viewing the app from
 * @param bidId {string} The ID of the bid, used for tracking
 */
export function externalTrackingImpressionUrl({ externalTrackers, bidId, webLocale }: TrackerType) {
	if (!externalTrackers?.length) return;

	handleExternalClickoutTrackers({
		externalTrackers: externalTrackers,
		trackerType: 'external_impression_tracker_fired',
		webLocale,
		bidId,
	});
}

/**
 * Check to see if we have consent before possibility handling inject HTML trackers
 *
 * @param externalTrackers {ExternalTracker[]} An array of external trackers
 * @param webLocale {WebLocale} The web locale of the page the user is viewing the app from
 * @param bidId {string} The ID of the bid, used for tracking
 */
export function injectExternalImpressionHtml({ externalTrackers, bidId, webLocale }: TrackerType) {
	if (!externalTrackers?.length) return;

	injectExternalHtmlTrackers({
		externalTrackers: externalTrackers,
		webLocale,
		bidId,
	});
}
